import '@css/main.scss';
import '@js/bootstrap';
import {Header} from "@js/utils/Header";
import {Slider} from "@js/utils/Slider";
import {Filters} from "@js/utils/Filters";
import {Woocommerce} from "@js/utils/Woocommerce";
import {Footer} from "@js/utils/Footer";
import {IframeCambridge} from "@js/utils/IframeCambridge";
import AOS from 'aos';
import {Accordeon} from "@js/utils/Accordeon";


window.onload = () => {
    new Header()
    new Slider()
    new Filters()
    new Woocommerce()
    new Footer()
    new IframeCambridge()
    new Accordeon()

    AOS.init();
}
