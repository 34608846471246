export class Accordeon {
    constructor() {
        this._accordeon = document.querySelectorAll('details')

        if (this._accordeon.length > 0) {
            this.init();
        }
    }

    init() {
        this._accordeon.forEach((accordeon) => {
            accordeon.addEventListener('click', (e) => {
                this._accordeon.forEach((accordeon) => {
                    if (accordeon !== e.target) {
                        accordeon.removeAttribute('open')
                    }
                })

                e.target.setAttribute('open', '')
            })
        })
    }
}
